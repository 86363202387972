<script lang="ts">

import { inject } from 'vue';
import CardInfo from '@/Components/CardInfo.vue';
import { SplideSlide } from '@splidejs/vue-splide';
import CardInfoContainer from '@/Components/CardInfoContainer.vue';
import GeneralGateway from '@/infra/gateway/generalGateway';
import { Money } from '@/utils/formats';
import {ICardInfo} from '@/Types/CardInfo';
import DesenvolvedorGateway from '@/infra/gateway/desenvolvedorGateway';
import modalService from '@/services/ModalService';
import {getOffset} from '@/services/Browser';
import InvestidorGateway from '@/infra/gateway/investidorGateway';
import { isMobileApp } from '@/services/Mobile';


export default {
    name: "PaginaIncorporador",
    components: {
        CardInfoContainer, CardInfo, SplideSlide
    },
    props : {
        userAccess: {type: Object, default: undefined}
    },
    setup() {
        return {
            generalGateway: inject<GeneralGateway>('generalGateway')!,
            desenvolvedorGateway: inject<DesenvolvedorGateway>('desenvolvedorGateway')!,
            investidorGateway: inject<InvestidorGateway>('investidorGateway')!
        }
    },
    data() {
        return {
            //variaveis de texto
            vantagens: [
               {
                title : "Maior comunidade de investidores imobiliários da américa latina",
                info: `Temos uma base de aproximadamente <span class="skeleton text-transparent">urbe</span>  mil investidores cadastrados, dentre os quais cerca de <span class="skeleton text-transparent">urbe</span>  mil são ativos. Por meio destes, já captamos mais de R$ <span class="skeleton text-transparent">urbe</span>  milhões para viabilizar <span class="skeleton text-transparent">urbe</span> empreendimentos espalhados pelo Brasil.`
               },

               {
                title : "Cobertura de exposição de caixa sem garantia real",
                info: 'Por não exigirmos garantia real, visto que oferecemos um crédito complementar ao financiamento bancário, o incorporador será avalista da operação e o empreendimento deve estar sob patrimônio de afetação.'
               },

               {
                title : "Financiamento adequado ao fluxo de caixa do projeto",
                info: "Estudamos e definimos os parâmetros da operação de acordo com as informações e indicadores do projeto, sempre respeitando as projeções do incorporador e buscando atender às suas necessidades de caixa."
               },
            ] as Array<ICardInfo>,


            possibilidades: [
               {
                title: 1,
                subTitle : "Aquisição <br>de Terrenos",
                info: "Estruturamos operações de dívida para aquisição de terrenos urbanos voltados para o desenvolvimento imobiliário.",
                button: {
                    name: "Saiba mais",
                    link: "mailto: institucional@urbe.me?subject=Interesse em Aquisição de Terrenos&body=Olá, minha empresa tem interesse em estruturar a Aquisição de um Terreno com a URBE.ME!"
                }
            },

               {
                title: 2,
                subTitle : "Execução <br>de Obra",
                info: "Estruturamos operações de dívida para cobertura da exposição de caixa do seu projeto imobiliário.",
                button: {
                    name: "Captar recursos",
                    link: "",
                    action: this.goToCaptarRecursos
                }
            },

               {
                title: 3,
                subTitle : "Antecipação <br>de Recebíveis",
                info: "Trabalhamos com parceiros para antecipar o seu fluxo de recebíveis futuro a partir de vendas já performadas.",
                button: {
                    name:"Saiba mais",
                    link: "mailto: institucional@urbe.me?subject=Interesse em Antecipação de Recebíveis&body=Olá, minha empresa tem interesse em estruturar a Antecipação de Recebíveis de um projeto com a URBE.ME!"
                }
            },
            ] as Array<ICardInfo>,

            etapasCaptacao: [
            {
                title: 1,
                subTitle : "Cadastro",
                info: `Cadastre sua Incorporadora <br><a id="cadastre" class="font-bold link-hover-anim">clicando aqui</a>`,
            },

            {
                title: 2,
                subTitle : "Projeto",
                info: `Cadastre o projeto pelo <br><a class="font-bold link-hover-anim" href="/dashboard/incorporador/">Painel do Incorporador</a>`,
            },

            {
                title: 3,
                subTitle : "Análise",
                info: "Faremos uma análise completa <br>da Incorporadora e do Projeto",
            },
            {
                title: 4,
                subTitle : "Captação",
                info: "Estruturaremos a <br>operação de financiamento",
            },
            {
                title: 5,
                subTitle : "Acompanhamento",
                info: "Gerencie seus Projetos e <br>preste contas aos Investidores",
            },
            ] as Array<ICardInfo>,


            Money,
            getOffset,
            mobileApp: isMobileApp(),
            incorporadorasData: undefined as Object | undefined,
        };
    },

    methods : {
        async updateTextoVantagens(){
            this.vantagens[0].info = `Temos uma base de aproximadamente ${this.incorporadorasData?.numero_usuarios} mil investidores cadastrados, dentre os quais cerca de ${this.incorporadorasData?.numero_investidores} mil são ativos. Por meio destes, já captamos mais de R$ ${this.incorporadorasData?.total_captado} milhões para viabilizar ${this.incorporadorasData?.numero_projetos} empreendimentos espalhados pelo Brasil.`
        },
        async getIncorporadorasData(){
            const response = await this.generalGateway.getDadosPaginaIncorporador();
            this.incorporadorasData = response;
            this.updateTextoVantagens();
            
        },
        
        goToCaptarRecursos(){
            const element = document.getElementById("quero-captar");
            if(!element) return;
            const top = this.getOffset(element).top;
            window.scroll({
            top: top,
            behavior: 'smooth'
            })
        },
        async openLoginModal(){    
            if(this.userAccess?.incorporadora) {
                window.open('/dashboard/incorporador', '_blank');
                return;
            }
            modalService.modalLoginCadastro('login',  {investidor: this.investidorGateway});
        },
    },

    async mounted() {
        this.getIncorporadorasData();

        let text = document.getElementById('cadastre');
        text?.addEventListener("click", () => {
            modalService.modalLoginCadastro('cadastro_incorporador', {desenvolvedor: this.desenvolvedorGateway});
        })
    },
}

</script>

<template>
    
    <!-- VANTAGENS -->
    <section class="w-full">
        <div class="ctn home-container">
            <div class="how-to-title flex flex-col gap-[2rem]">
                <h2 class="font-home-title my-[7rem]" data-aos="fade-right">Vantagens</h2>
            </div>
            <div class="flex justify-between flex-wrap gap-[6rem]">
                <div v-for="vantagem in vantagens" class="px-[2.8rem] w-[35rem] flex justify-between flex-col gap-[3rem]">
                    <div class="font-normal text-[2.6rem] leading-[105%]">{{ vantagem.title }}</div>
                    <div v-if="vantagem.info" class="grey-label leading-[105%] text-left text-[1.6rem] font-normal" v-html="vantagem.info"></div>
                    <div v-else class="w-full skeleton h-full rounded-5"></div>
                </div>
            </div>
        </div>
    </section>

    <!-- POSSIBILIDADES -->
    <section class="w-full mt-[7rem]">
        <CardInfoContainer 
            title="Possibilidades"
            arrow-id="arrow-vantagens"
            :breakpoint="1280"
            customArrowClass="hide"
            >
            <template v-for="{title, subTitle, info, button} in possibilidades">
                <SplideSlide>
                    <CardInfo>
                        <div class="w-full h-full p-[2.8rem] flex flex-col justify-between">
                            <div class="flex flex-col justify-start gap-[2rem] w-full">
                                <span class="font-bold text-[4rem]">{{ title }}</span>
                                <h3  class="font-normal text-[2.8rem] leading-[1.2]" v-html="subTitle"></h3>
                                <span class="grey-label leading-[105%] text-left text-[1.6rem] font-normal">
                                    {{ info }}
                                </span>
                            </div>
                            <div v-if="button" class="button-wrapper w-full">
                                <a v-if="button.action" class="w-full flex justify-center dark-blue-bg text-white font-normal p-4 rounded-[5rem] cursor-pointer" type="button" @click="button.action" >{{ button.name }}</a>
                                <a v-else class="w-full flex justify-center dark-blue-bg text-white font-normal p-4 rounded-[5rem]" type="button" :href="button.link" target="_blank">{{ button.name }}</a>
                            </div>
                        </div>
                    </CardInfo>
                </SplideSlide>
            </template>
        </CardInfoContainer>
    </section>

    <section class="w-full mt-[7rem]">
        <CardInfoContainer  
                title="Incorporadoras" 
                tipo="loop">
            <template v-if="incorporadorasData && incorporadorasData?.incorporadoras_carrosel.length > 0">
                <SplideSlide v-for="{logotipo_url, nome_fantasia, endereco, rating, total_captado, numero_de_investidores} in incorporadorasData.incorporadoras_carrosel">
                    <CardInfo
                    :height="46"
                    background="dark-blue-bg">

                    <div class="w-full h-full p-[2.8rem] flex flex-col justify-between text-white">
                        <div class="w-[18rem] h-[5rem] items-center flex">
                            <img :src="logotipo_url" class="filter-white max-h-[5rem]">
                        </div>
                        <div class="h-[8rem] flex items-center text-[2.8rem] font-medium">
                            <h3>
                                {{ nome_fantasia }}
                            </h3>
                        </div>
                        <p class="text-[1.8rem]">{{ endereco }}</p>

                        <div class="text-[1.8rem]">
                            <p class="grey-text">Rating de Crédito</p>
                            <p>{{ rating }}</p>
                        </div>
                        

                        <div class="text-[1.8rem]">
                            <p class="grey-text">Total Captado</p>
                            <p>{{ Money(total_captado) }}</p>
                        </div>

                        <div class="text-[1.8rem]">
                            <p class="grey-text">Investidores</p>
                            <p>{{ numero_de_investidores }}</p>
                        </div>
                    </div>
                    </CardInfo>
                </SplideSlide>
            </template>
            <template v-else>
                <SplideSlide v-for="item in 3">
                    <CardInfo
                    classes="mt-[7rem]"
                    :height="46"
                    background="skeleton">
                    </CardInfo>
                </SplideSlide>
            </template>
        </CardInfoContainer>
    </section>

    <section class="w-full mt-[7rem]" id="quero-captar">
        <div class="ctn home-container">
                <div class="how-to-title">
                    <h2 class="font-home-title mt-[7rem]" data-aos="fade-right">Quero captar!</h2>
                </div>
                <div class="flex py-[5rem] flex-wrap items-start md:flex-nowrap md:py-[10rem] gap-[2rem] md:gap-0">
                    <div class="flex flex-col items-center text-[2rem] px-[2rem] gap-[1rem] w-[100%] sm:w-[50%] md:w-[20%]" v-for="{title, subTitle, info} in etapasCaptacao">
                        <div class="flex flex-col items-center gap-4">
                            <div class="dark-blue-bg w-[5rem] h-[5rem] text-white flex justify-center items-center rounded-[50%]">{{ title }}</div>
                            <p>{{ subTitle }}</p>
                        </div>
                        <div class="text-center text-[1.4rem]" v-html="info ">
                        </div>
                    </div>
                </div>
        
        </div>
     
    </section>

    <section v-if="!mobileApp" class="w-full bg-white">
        <div class="ctn home-container py-[7rem]">
            <div class="flex flex-wrap justify-between items-center flex-col lg:flex-row gap-[2rem_3rem]">
                <h2 class="text-[4rem]">Painel do Incorporador</h2>
                <p class="font-large">Visualizar Painel do Incorporador detalhado</p>
                <a class="text-white dark-blue-bg rounded-[5rem] p-[1rem_2.7rem] pointer tracking-wider text-white-shadow-hover" @click="openLoginModal">Conhecer</a>
            </div>
        </div>
    </section>


</template>

