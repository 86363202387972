<script lang="ts">

import { isMobile } from '@/services/Mobile';

export default {
    name: 'CardInvestimentoContainer',
    props: {
        captacao: {type: Object, required: true},

    },
    data() {
        return {
            isMobile
        }
    },
    methods: {
        backgroundParallax() {
            let el = document.getElementsByClassName("background-parallax")[0];
            const speed = 0.3;
        
            const windowYOffset = window.pageYOffset;                

            const elBackgrounPos = "center  " + `calc(50% + ${((windowYOffset-el.offsetTop) * speed)}px)`;
            el.style.backgroundPosition = elBackgrounPos;
        },
    },
    mounted() {
        window.onscroll = () => {
            !isMobile() && this.backgroundParallax();
        }
    }
}
</script>

<template>
    <div class="flex flex-col background-parallax md:bg-fixed h-full" :style="`background-image: url('${captacao.imagem_projeto}');`">
        <div style="background-color: rgba(0,0,0,.4);" class='h-full md:min-h-[calc(100vh-7rem)] min-[1162px]:pt-[7rem]'>
            <div class="ctn home-container mt-[6rem] md:mt-[3.5rem] md:flex md:flex-col md:gap-[2rem] h-full md:justify-center mb-[3.5rem]">
                <div class="flex flex-wrap">
                    <div class="w-[50%] mt-[4rem] md:mt-0">
                        <img :src="captacao.logotipo_incorporadora" class="filter-white max-w-[16rem] max-h-[8rem]">                       
                        <h1 class="text-white text-[5rem] md:text-[6rem] font-medium">{{ captacao.nome_projeto }}</h1>
                        <h2 class="font-large text-white">{{ captacao.nome_captacao }}</h2>
                    </div>
                    <div class="flex w-full flex-wrap md:flex-nowrap h-full my-[3rem] min-[912px]:w-1/2 justify-between gap-[1rem] text-white text-[2rem] md:text-[1.8rem]">
                        <div class="flex flex-col gap-[.5rem] gap-y-[1.5rem] md:gap-[1rem] md:gap-y-[1rem]">
                            <p class="flex gap-[1.5rem]"><span class="font-bold">Cidade</span> {{ captacao.endereco }}</p>
                            <p class="flex gap-[1.5rem]"><span class="font-bold">TIR </span>{{ captacao.tir  }}</p>
                            <p class="flex gap-[1.5rem]"><span class="font-bold">Taxa mínima </span>{{ captacao.rentabilidade_minima  }} a.a</p>
                        </div>
                        
                        <div class="flex flex-col gap-[.5rem] gap-y-[1.5rem] md:gap-[1rem] md:gap-y-[1rem]">
                            <p class="flex gap-[2rem]"><span class="font-bold">Pagamento </span>{{ captacao.pagamento  }}</p>
                            <p class="flex gap-[1.5rem]"><span class="font-bold">Carência </span>{{ captacao.carencia  }}</p>
                            <p class="flex gap-[1.5rem]"><span class="font-bold">Vencimento </span>{{ captacao.vencimento  }}</p>
                        </div>

                    </div>

                    
                </div>   
                <div class="h-full">
                    <slot />
                </div> 
               
            </div>
        </div>
    </div>
</template>
