<script lang="ts">

import { inject } from 'vue';
import GeneralGateway from '@/infra/gateway/generalGateway';
import {ICardInfo} from '@/Types/CardInfo';
import CardContainer from '@/Components/CardContainer.vue';
import Card from '@/Components/Card.vue';
import BaseTable from '@/Components/BaseTable.vue';
import BaseItem from '@/Components/BaseItem.vue';
import {DadosPaginaInvestidor} from '@/Types/General';
import LineChart from '@/Components/LineChart.vue';
import GoogleMap from '@/Components/GoogleMap.vue';
import InvestidorGateway from '@/infra/gateway/investidorGateway';
import modalService from '@/services/ModalService';
import BarChart from '@/Components/BarChart.vue';



import infoIcon from '@/assets/info-icon.svg';
import medalIcon from '@/assets/medal.svg';
import checkIcon from '@/assets/check.svg';
import closedBookIcon from '@/assets/closed-book.svg';
import empiricaLogo from '@/assets/empirica-logo.png';
import socinalLogo from '@/assets/socinal-logo.png';
import fitbankLogo from '@/assets/fitbank-logo.png';
import lbaLogo from '@/assets/lba-capital-logo.png';
import ghiaLogo from '@/assets/ghia-logo.png';
import { colors } from '@/styles/styles'


import { Percent } from '@/utils/formats'; 
import { isMobile } from '@/services/Mobile';



export default {
    name: "PaginaInvestidor",
    components : {
        CardContainer,
        Card,
        LineChart,
        BarChart,
        GoogleMap,
        BaseTable,
        BaseItem
    },
    props: {
        userAccess: {type: Object, default: undefined}
    },
    setup() {
        return {
            investidorGateway: inject<InvestidorGateway>('investidorGateway')!,
            generalGateway: inject<GeneralGateway>('generalGateway')!,
        }
    },
    data() {
        return {


            //ICONS 
            medalIcon,
            checkIcon,
            infoIcon,
            closedBookIcon,
            empiricaLogo,
            socinalLogo,
            fitbankLogo,
            ghiaLogo,
            lbaLogo,
            colors,

            mobile: isMobile(),
           
            dadosRequest: undefined as DadosPaginaInvestidor | undefined,

            Percent,

            cardsInvestidor: [
                {
                    title: 'Sou Investidor Institucional',
                    link: "mailto: institucional@urbe.me?subject=Interesse em Investimento Institucional&body=Olá, minha empresa tem interesse em investir pela URBE.ME!"
                },
                {
                    title: 'Sou Investidor Individual',
                    link: '/'
                },
                {
                    title: 'Sou Parceiro de Negócios',
                    link: "mailto: institucional@urbe.me?subject=Interesse em Parceria&body=Olá, tenho interesse em estruturar uma parceria com a URBE.ME!"
                }
            ] as Array<{title: string, link: string}>,
            

            statusProjetoDict : {
                'a': 'Inadimplente'
            } as Object,

            parceiros : [
                {
                    icon: empiricaLogo,
                    link : 'https://empirica.com.br/'
                },
                {
                    icon: lbaLogo,
                    link : 'https://www.linkedin.com/company/lba-capital/'
                },
                {
                    icon: socinalLogo,
                    link : 'https://www.socinal.com.br/'
                },
                {
                    icon: fitbankLogo,
                    link : 'https://fitbank.com.br/'
                }, 
                {
                    icon: ghiaLogo,
                    link: 'https://ghiaasset.com.br/'
                }

            ] as Array<{icon:string,link:string}>,

           vantagens: [
               {
                title : "Rentabilidade e Diversificação",
                info: "Investimento na incorporação imobiliária, a etapa mais rentável deste mercado, de forma diversificada em localização, escala e padrão de empreendimento."
               },

               {
                title : "Agilidade e<br> Simplicidade",
                info: "Em um processo totalmente digital e sem taxas, o investimento é realizado em poucos cliques e de forma segura sob a regulamentação do Banco Central."
               },

               {
                title : "Gestão e Acompanhamento",
                info: "Por meio do Painel do Investidor, é possível acompanhar, detalhadamente, o desempenho dos empreendimentos investidos e os resultados obtidos."
               },
            ] as Array<ICardInfo>,
        };
    },
    methods : {
        async getDados() {
            const response = await this.generalGateway.getDadosPaginaInvestidor();
            this.dadosRequest = response;
        },
        async openLoginModal(){    
            if(this.userAccess?.usuario) {
                window.open('/dashboard/investidor', '_blank');
                return; 
            }
            modalService.modalLoginCadastro('login', {investidor: this.investidorGateway});
        },
        async openDetailModal(text: string){
            text && modalService.mensagem(text,80)
        },
        async showHint(){
            modalService.mensagem(`<b style="color: ${colors.white}">Adimplentes:</b> pagamentos em dia, incluindo as captações que sofreram alterações no fluxo original e que a incorporadora está pagando no novo prazo estabelecido.<br><br> ${this.dadosRequest?.percentual_inadimplentes ? `<b style="color: ${colors.grey}">Inadimplentes:</b> pagamentos atrasados por mais de ${this.dadosRequest.regra_dias_inadimplencia} dias, incluindo repactuações de dívida com atraso.<br><br>`: ''}<b style="color: ${colors.red}">Perda de Capital:</b> confirmação de que os pagamentos não serão feitos.<br>`)
        }
    },

    async mounted() {
        this.getDados();
    }
}

</script>

<template>
    <!-- VANTAGENS -->
    <section class="w-full">
        <div class="ctn home-container">
            <div class="how-to-title flex flex-col gap-[2rem]">
                <h2 class="font-home-title my-[7rem]" data-aos="fade-right">Vantagens</h2>
            </div>
            <div class="flex justify-between flex-wrap gap-[6rem]">
                <div v-for="{title,info} in vantagens" class="px-[2.8rem] w-[35rem] flex justify-between flex-col gap-[3rem]">
                    <div class="font-normal text-[2.6rem] leading-[105%]" v-html="title"></div>
                    <div class="grey-label leading-[105%] text-left text-[1.6rem] font-normal">{{ info }}</div>
                </div>
            </div>
        </div>
    </section>

    <section class="mt-[7rem] w-full">
        <div class="ctn home-container">
            <div class="flex flex-wrap">
                <div class="w-[100%] lg:w-[50%] flex flex-col-reverse lg:flex-col justify-between">
                    
                    <div class="how-to-title flex flex-col gap-[2rem] mt-[4rem]">
                        <h2 class="font-home-title leading-[1.2]" data-aos="fade-right">Histórico de<br> Rentabilidade</h2>
                    </div>

                    <div class="text-white flex flex-col gap-[1.2rem] text-[1.8rem]">
                        <a v-for="{link,title} in cardsInvestidor" class="dark-blue-bg h-[7rem] flex items-center justify-between px-[1.8rem] rounded-[1.5rem] pointer" :href="link">
                            {{title}}
                        <i class="fas fa-arrow-right"></i>
                        </a>
                        
                    </div>

                </div>

                <div class="flex flex-col w-[100%] mt-[3rem] lg:mt-[0rem] lg:px-[1.5rem] lg:w-[50%]">
                    <div class="p-[1.2rem] bg-white rounded-[1.5rem]">
                        <CardContainer :primary="true" class="grid-cols-12" :height="8">
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3" titleClass="font-mini" title='Empreendimentos' :body="dadosRequest?.numero_projetos"/>
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3" titleClass="font-mini" title='Total Investido' :body="dadosRequest?.total_captado"/>
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3" titleClass="font-mini" title='Total Estimado' :body="dadosRequest?.total_bruto_estimado"/>
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3" titleClass="font-mini" title='Rentabilidade' 
                            :body="dadosRequest ? `${Percent(dadosRequest?.rentabilidade_media_total)} a.a` : null"/>
                        </CardContainer>

                        <CardContainer addClass="finalizado" class="grid-cols-12" :height="8">
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3" titleClass="font-mini" :icon="medalIcon" title='Finalizados'/>
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3" titleClass="font-mini" title='Total Investido' :body="dadosRequest?.total_captado_finalizado"/>
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3" titleClass="font-mini" title='Total Retornado' :body="dadosRequest?.total_retornado"/>
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3" titleClass="font-mini" class="" title='TIR Realizada' 
                            :body="dadosRequest ? `${Percent(dadosRequest?.rentabilidade_media_finalizado)} a.a` : null"/>
                        </CardContainer>

                        
                        <CardContainer  addClass="light-blue-std-bg" class="grid-cols-12" :height="8">
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3" titleClass="font-mini" :icon="checkIcon" title='Ativos'/>
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3" titleClass="font-mini" title='Total Investido' :body="dadosRequest?.total_captado_em_andamento"/>
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3" titleClass="font-mini" title='Total Estimado' :body="dadosRequest?.total_estimado_a_receber"/>
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3" titleClass="font-mini" class="" title='TIR Projetada' 
                            :body="dadosRequest ? `${Percent(dadosRequest?.rentabilidade_media_em_andamento)} a.a` : null"/>
                        </CardContainer>

                        <div class="mt-[3rem]">
                            <LineChart
                            v-if="dadosRequest"
                            :height="mobile ? 300 : 200"
                            :hide-label="true"
                            :percent="true"
                            unit="month"
                            y-axis-title="TIR Realizada (% a.a)"
                            x-axis-title="Data de Captação"
                            :show-grid="false"
                            :showYLabel="true"
                            :max="null"
                            additionalLabelText="a.a"
                            :dataSets="[
                                {
                                    label: 'Média Realizada',
                                    data: dadosRequest?.dados_grafico_rentabilidade_realizada_media,
                                    curve: true,
                                },
                                {
                                    label: 'Mínima Projetada',
                                    data: dadosRequest?.dados_grafico_rentabilidade_minima_media,
                                    curve: true,
                                    borderColor: colors.grey,
                                    backgroundColor: colors.grey,
                                },
                                {
                                    label: '',
                                    data: dadosRequest?.dados_grafico_rentabilidade_realizada,
                                    extra: true,
                                    type: 'bubble',
                                    borderColor: 'transparent',
                                    pointRadius: 3,
                                    backgroundColor: colors.lightBlue
                                },
                                
                            ]"
                        />
                        <div v-else class="skeleton w-full h-[30rem] rounded-[1.5rem]">

                        </div>
                        </div>
                        
                    </div>

                </div>

                <div class="w-[100%] flex flex-col px-[1.5rem] mt-[7rem] mb-[7rem]">
                    <div class="how-to-title flex flex-col gap-[2rem] mb-[3rem]">
                        <h2 class="font-home-title leading-[1.2]" data-aos="fade-right">Diversificação Geográfica</h2>
                    </div>

                    <GoogleMap 
                        :show-title="false"
                        :data="dadosRequest?.informacoes_mapa"
                        classes="h-[60rem]"/>

                </div>

                <div class="w-[100%] lg:w-[50%] flex flex-col justify-between">
                    
                    <div class="how-to-title flex flex-col gap-[2rem] lg:mt-[4rem]">
                        <h2 class="font-home-title leading-[1.2]" data-aos="fade-right">Histórico de<br> Adimplência</h2>
                    </div>

                    <div class="white-bg rounded-[1.5rem] p-[1.2rem] mt-[3rem] lg:mt-[0rem]">
                        <BarChart v-if="dadosRequest" 
                            :display-legend="true"
                            :height="190"
                            y-axis-title="Nº de Captações"
                            x-axis-title="Ano de Captação"
                            type="qtd"
                            :datas="
                                {
                                labels : dadosRequest?.anos_unicos,
                                data: [
                                        {
                                            type: 'bar',
                                            data: dadosRequest.quantidades_captacoes_perda_de_capital,
                                            
                                            label: 'Perda de Capital',
                                            backgroundColor: colors.red,
                                        },
                                        {
                                            type: 'bar',
                                            data: dadosRequest.quantidades_captacoes_inadimplentes,
                                            label: 'Inadimplentes',
                                            backgroundColor: colors.grey,
                                        },
                                        {
                                            type: 'bar',
                                            data: dadosRequest.quantidades_captacoes_em_dia,
                                            
                                            label: 'Adimplentes',
                                            backgroundColor: colors.darkBlue,
                                        },

                                ]}" 
                            :add-annotation="{line1: {
                                type: 'line',
                                yMin: 0,
                                yMax: 45,
                                xMax: 2.5,
                                xMin: 2.5,
                                borderColor: colors.darkBlue,
                                borderWidth: 1.5,
                                borderDash: [2, 2],
                            },
                            text : {
                                type: 'label',
                                yValue: 42,
                                xValue: 2.5,
                                borderRadius: 4,    
                                backgroundColor: colors.darkBlue,
                                content: ['Mudança de Modelo Regulatório'],
                                color: '#fff',
                                font: {
                                    size: 12,
                                }
                            }
                            }"/>

                        <div v-else class="skeleton w-full h-[30rem] rounded-[1.5rem]"></div>

                    </div>

                </div>

                <div class="w-[100%] mt-[3rem] lg:mt-[0rem] lg:px-[1.5rem] lg:w-[50%]">
                    <div class="white-bg rounded-[1.5rem] p-[1.2rem]">
                        <CardContainer  addClass="light-blue-std-bg" class="grid-cols-12" :height="8">
                            <Card :colSpan="3" :hint-function="showHint" :hintIcon="infoIcon" wrapperClass="col-span-6 sm:col-span-3 justify-center" titleClass="font-mini" :icon="medalIcon" title='Adimplência'/>
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3 justify-center" titleClass="font-mini" title='Adimplentes' :body="Percent(dadosRequest?.percentual_em_dia)"/>
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3 justify-center" titleClass="font-mini" title='Inadimplentes' body-class="grey-label" :body="Percent(dadosRequest?.percentual_inadimplentes)"/>
                            <Card :colSpan="3" wrapperClass="col-span-6 sm:col-span-3 justify-center" titleClass="font-mini" class="indadimplente" title='Perda de Capital' body-class="text-inadimplente" :body="Percent(dadosRequest?.percentual_perda_de_capital)"/>
                        </CardContainer>
                        <div class="flex gap-2 font-bold my-[3rem]">
                            <img :src="closedBookIcon"> Detalhes sobre Inadimplências
                        </div>
                        <BaseTable 
                            :objects="dadosRequest?.captacoes_inadimplentes_tabela"
                            ref="table"
                            :maxHeight="40"
                            header-margin="mb-4 mt-4"
                            :overflow-hidden="false"
                            colsClass="grid-cols-4 xs:grid-cols-5"
                            :labels="{
                            'Projeto': {size: 2, attribute: {name: 'projeto__nome', type: 'string'}},
                            'Incorporadora': {size: 1, attribute: {name: 'projeto__incorporadora__nome_fantasia', type: 'string'}, hide: 'hidden xs:flex'},
                            'Status': {size: 1, attribute: {name: 'status_pagamento', type: 'string'}},
                            'Detalhes': {size: 1, attribute: {name: 'projeto__nome', type: 'string'}},
                        }">
                        <BaseItem 
                            v-if="dadosRequest" background="white-semi-bg" 
                            v-for="captacao in dadosRequest?.captacoes_inadimplentes_tabela" 
                            :size="5" 
                            :height="4"
                            break-point-size="xs"
                            :new-size="4">
                                <div class='text-[1.4rem] font-bold flex items-center col-span-2 px-5 truncate' :title="captacao.projeto__nome"><p>{{ captacao.projeto__nome }}</p></div>
                                <div class='text-[1.4rem] hidden justify-start items-center col-span-1 px-5 xs:flex truncate' :title="captacao.projeto__incorporadora__nome_fantasia"><a class="hover:underline" :href="captacao.projeto__incorporadora__site">{{ captacao.projeto__incorporadora__nome_fantasia }}</a></div>
                                <div class='text-[1.4rem] flex justify-center items-center col-span-1'><p>{{ statusProjetoDict[captacao.status_pagamento] }}</p></div>
                                <div class='text-[1.4rem] flex justify-center items-center col-span-1' @click="$event => openDetailModal(captacao.detalhes_inadimplencia)">
                                    <p v-if="captacao.detalhes_inadimplencia" class="font-title pointer">+</p>
                                </div>
                        </BaseItem>
                        <BaseItem v-if="!dadosRequest" background="skeleton" v-for="index in 5" :size="5" :height="4">
                            <div v-for="x in 5" class='font-mini flex justify-center items-center skeleton col-span-1'></div>
                        </BaseItem>
                        </BaseTable>

                    </div>
                </div>

                <div class="w-[100%] flex flex-col">
                    
                    <div class="how-to-title flex flex-col gap-[2rem] mt-[7rem]">
                        <h2 class="font-home-title leading-[1.2]" data-aos="fade-right">Parceiros Institucionais</h2>
                    </div>

                    <div class="flex flex-wrap justify-center items-center gap-[4rem_8rem] my-[8rem]">
                        <a v-for="{link, icon} in parceiros" :href="link" target="_blank">
                            <img :src="icon" class="max-h-[6rem] md:max-h-[8rem]">
                        </a>

                    </div>

                </div>

            </div>
        </div>
    </section>
    <section class="w-full bg-white">
        <div class="ctn home-container py-[7rem]">
            <div class="flex flex-wrap justify-between items-center flex-col lg:flex-row gap-[2rem_3rem]">
                <h2 class="text-[4rem]">Painel do Investidor</h2>
                <p class="font-large">Visualizar Painel do Investidor detalhado</p>
                <a class="text-white dark-blue-bg rounded-[5rem] p-[1rem_2.7rem] pointer tracking-wider text-white-shadow-hover" @click="openLoginModal">Conhecer</a>
            </div>
        </div>
    </section>

</template>

